import { useEffect, useState, FunctionComponent } from "react";

const Status: FunctionComponent = () => {
    const [status, setStatus] = useState<any>(null);

    useEffect(() => {
        fetch("/api/status")
        .then(response => response.json())
        .then(s => {
            const body = s;
            setStatus(body);
            })
        .catch(x => console.log(x));
    }, []);

    const statusField = () => {
        return JSON.stringify(status, null, 4);
    }

    return <div className="container content">
    <div className="section">
      <div className="box">
        <h1>Status</h1>
        <div>{statusField()}</div>
      </div>
    </div>
  </div>;
  }

export default Status