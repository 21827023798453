export interface ICourse {
    id: number;
    avg: number;
    DR: number | null;
    subject: string;
    title: string;
    instructors: string;
    class: string;
    students: number;
    std: number | null;
    "C+": number | null;
    "C-": number | null;
    "A+": number | null;
    "A-": number | null;
    "A": number | null;
    "C": number | null;
    "B": number | null;
    "D": number | null;
    "F": number | null;
    "W": number | null;
    "D-": number | null;
    "D+": number | null;
    "B-": number | null;
    "B+": number | null;
}

export interface ICourseSemesterData {
    course_code: string,
    semester_name: string,
    semesterId: number,
    section_year: number,
    grades_given: number,
    gpa: number | null
}

export interface ISection {
    id: number;
    semester: string;
    DR: number | null;
    subject: string;
    title: string;
    gpa: number;
    instructor: string;
    class: string;
    section: string;
    "C+": number | null;
    "C-": number | null;
    "A+": number | null;
    "A-": number | null;
    "A": number | null;
    "C": number | null;
    "B": number | null;
    "D": number | null;
    "F": number | null;
    "W": number | null;
    "D-": number | null;
    "D+": number | null;
    "B-": number | null;
    "B+": number | null;
}

export interface ICourseHistory {
    course: ICourse,
    sections: ISection[]
}

export interface IUniversity { 
    longName: string,
    shortName: string
}
  
export const universities: { [shortName: string]: IUniversity } = {
    "uva": { longName: "University of Virginia", shortName: "uva"},
    "gmu": { longName: "George Mason University", shortName: "gmu"},
    "jmu": { longName: "James Madison University", shortName: "jmu"},
    "umd": { longName: "University of Maryland", shortName: "umd"},
};

export interface IBlogPostMetadata {
    title: string,
    description: string,
    author: string,
    date: Date,
    slug: string,
    tags: string[],
    image: string | null,
    attribution: string | null,
    alt: string | null
}

export interface IBlogPost {
    metadata: IBlogPostMetadata,
    content: string
}