import { Component } from "react";
import "whatwg-fetch";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { IBlogPostMetadata } from "./models/models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

interface IBlogState {
  posts: IBlogPostMetadata[]
}

export const blogPostComparer = (a: IBlogPostMetadata, b: IBlogPostMetadata) => a.date > b.date ? 1 : -1;

class Blog extends Component<{}, IBlogState> {
  constructor(props: any) {
    super(props);
    this.state = {
      posts: []
    };
  }
  componentDidMount() {
    this.loadData();
  }
  loadData() {
    fetch("/api/blog")
      .then(response => response.json())
      .then(json => {

        // sort posts latest first
        json.sort(blogPostComparer)
          .reverse();

        this.setState({ posts: json })
      });
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>VA Grades - Blog</title>
        </Helmet>
        <div className="container content">
          <div className="section">
                {this.state.posts.map((post: IBlogPostMetadata) => {
                  return (
                    <ul key={post.title}>
                      <Link to={"/blog/" + post.slug}>
                          <div className="box">
                            <div className="columns">
                              <div className="column is-two-thirds">
                                <div className="title has-text-weight-medium">{post.title}</div>
                                <div className="subtitle is-italic">{post.description}</div>
                                <div className="subtitle is-size-6">
                                  <span style={{paddingRight: "1rem"}}>
                                    <FontAwesomeIcon icon={faCalendar} />
                                  </span>
                                  {new Date(post.date).toDateString()}
                                </div>
                              </div>
                              {post.image !== null && <div className="column">
                                <figure className="image is-3x2">
                                  <img src={post.image} alt={post.alt ?? ""} />
                              </figure>
                              </div>}
                            </div>
                          </div>
                    </Link>
                  </ul>
                  );
                })}
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;

