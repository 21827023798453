import { Component } from "react";
import "whatwg-fetch";
import ReactTable, { Column } from "react-table";
import { Helmet } from "react-helmet";
import "react-table/react-table.css";
import { matchSorter } from "match-sorter";
import { Link } from "react-router-dom";
import { ICourse, IUniversity } from "./models/models";

interface ICollegeProps {
  api: string,
  link: string,
  university: IUniversity
}

interface ICollegeState {
  data: ICourse[],
  loading: boolean
}

const tableOptions = {
  showPagination: true,
  showPageSizeOptions: true,
  showPageJump: true,
  collapseOnSortingChange: true,
  collapseOnPageChange: true,
  collapseOnDataChange: true,
  freezeWhenExpanded: false,
  filterable: true,
  sortable: true,
  resizable: true
}

class College extends Component<ICollegeProps, ICollegeState> {
  constructor(props: ICollegeProps) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.state = {
      data: [],
      loading: true
    };
  }

  loadData() {
    fetch(this.props.api)
      .then(response => response.json())
      .then(json => {
        this.setState({
          data: json,
          loading: false
        });
      })
      .catch(ex => console.log("parsing failed", ex));
  }
  componentDidMount() {
    this.loadData();
  }

  render() {
    const data = this.state.data;
    const columns: Array<Column> = [
      {
        Header: "Class",
        accessor: "class",
        filterMethod: (filter: { value: string}, rows: string[]) => 
          matchSorter(rows, filter.value, { keys: ["class"] }),
        filterAll: true,
        maxWidth: 250,
        Cell: (row: { value: string }) => <Link
          to={`${this.props.link}${row.value}`}
          className="button  is-ghost is-fullwidth"
      >
        {row.value}
      </Link>
      },
      {
        Header: "Title",
        accessor: "title",
        filterMethod: (filter: { value: string}, rows: string[]) =>
          matchSorter(rows, filter.value, { keys: ["title"] }),
        filterAll: true,
      },
      {
        Header: "Professors",
        accessor: "instructors",
        filterMethod: (filter: { value: string}, rows: string[]) =>
          matchSorter(rows, filter.value, {
            keys: ["instructors"],
            threshold: matchSorter.rankings.CONTAINS
          }),
        filterAll: true,
      },
      {
        Header: "GPA",
        accessor: "avg",
        Cell: (row: {value: number }) => <p>{String(row.value).substring(0, 4)}</p>,
        filterAll: true,
        filterable: false,
        maxWidth: 150
      },
      {
        Header: "Students",
        accessor: "students",
        Cell: (row: { value: string }) => <p>{row.value.toLocaleString()}</p>,
        maxWidth: 150,
        filterable: false
      },
    ];
    return (
      <div className="container" >
        <Helmet>
          <title>{"VA Grades - " + this.props.university.longName}</title>
          <meta name="description" content={`VA Grades has real grade data from ${this.props.university.longName}`}  />
        </Helmet>
        <div className="section" style={{padding: "1rem" }}>
          <div className="title has-text-weight-medium">{this.props.university.longName + " Grades"}</div>
        </div>
        <div className="section" style={{paddingTop: "1rem"}}>
          <ReactTable
            className="-striped -highlight"
            data={data}
            columns={columns}
            defaultSorted={[
              {
                id: "students",
                desc: true
              }
            ]}
            defaultPageSize={20}
            defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
            loading={this.state.loading}
            {...tableOptions} />
        </div>
      </div>
    );
  }
}

export default College;

