import { FunctionComponent, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ICourse, IUniversity, universities } from "./models/models";
import Select, { components } from "react-select";
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

// these are used in react-select
export type SchoolOption = {
    label: string, 
    value: IUniversity
}

// these are used in react-select
export type CourseOption = {
    label: string,
    value: string
}

// these are used to group options into sections like "popular" and "similar"
export type GroupOptions = {
    label: string,
    options: CourseOption[]
}

interface ISearchBarProps {
    selectedUniversity: IUniversity
}

const SearchBar: FunctionComponent<ISearchBarProps> = (props: ISearchBarProps) => {
    const [selectedUniversity, setSelectedUniversity] = useState<IUniversity>(props.selectedUniversity);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedCourse, setSelectedCourse] = useState<CourseOption | null>(null);

    const history = useHistory();

    const schoolOptions: SchoolOption[] = Object.values(universities)
            .map(university => ({ label: university.shortName.toUpperCase(), value: university }));
    const defaultSchoolOption: SchoolOption = { label: props.selectedUniversity.shortName.toUpperCase(), value: props.selectedUniversity };

    const getPopularOptionsAsync = async (uniShortName: string): Promise<CourseOption[]> => {
        const results = await fetch("/api/" + uniShortName + "/search");
        const x: ICourse[] = await results.json();

        const options: CourseOption[] = x.map(entry => ({
            value: entry.class,
            label: `${entry.class}: ${entry.title}`
        }));

        return options
    }

    const searchMatchesAsync = async (university: IUniversity, searchTerm: string): Promise<GroupOptions[]> => {
        if (searchTerm == "")
        {
            const popular = await getPopularOptionsAsync(university.shortName);
            return [{ label: "Popular courses", options: popular }];
        }

        const results = await fetch("/api/" + selectedUniversity?.shortName + "/search/" + searchTerm);
        const x: ICourse[] = await results.json();
        
        const options: CourseOption[] = x.map(entry => ({ 
            value: entry.class, 
            label: `${entry.class}: ${entry.title}` 
        }));

         return  [{ label: "Similar courses", options: options}];
    }

    const onSchoolChange = (e: { value: IUniversity | undefined }) => {
        if (e.value)
        {
            setSelectedUniversity(e.value);
            setSelectedCourse(null);
        }
    }

    const schoolPicker = () => {
        return <div>
            <Select 
                options={schoolOptions} 
                onChange={(e: any) => onSchoolChange(e)} 
                styles={{
                    control: (base) => ({
                        ...base, 
                        borderTopRightRadius: 0, 
                        borderBottomRightRadius: 0, 
                        boxShadow: "none",
                        borderRightColor: "ghostwhite",
                        backgroundColor: "ghostwhite"}),
                    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                    }}
                placeholder="Pick school..."
                menuPortalTarget={document.body}
                menuPosition={'fixed'} 
                defaultValue={defaultSchoolOption}
                components={ {IndicatorSeparator: null} }/>
        </div>;
    }

    const searchInput = () => {
        return <AsyncSelect 
            key={selectedUniversity.shortName} // we want to force a refresh if the selected school changes
            value={searchTerm ?? ""}   
            onInputChange={(input: string) => setSearchTerm(input)} 
            onChange={(course: any) => setSelectedCourse(course)}
            defaultOptions={true}
            loadOptions={(value: string) => searchMatchesAsync(selectedUniversity, value)}
            placeholder="Search a course..."
            styles={{menu: (base) => ({ ...base, zIndex: 9999 }),
                    control: (base) => ({
                        ...base, 
                        borderBottomLeftRadius: 0,
                        borderTopLeftRadius: 0,
                        borderLeftWidth: 0}),
                    menuPortal: provided => ({ ...provided, zIndex: 9999 })}}
            menuPortalTarget={document.body}
            menuPosition={'fixed'} 
            components={ {DropdownIndicator: SearchDropdownIndicator} } />;
    }

    useEffect(() => {
        if (selectedCourse !== null)
        {
            history.push({
                pathname: "/" + selectedUniversity?.shortName + "/" + selectedCourse.value,
            });
        }
    }, [selectedCourse]);

    return <div>
        <div style={{
                display: "flex", 
                flexDirection: "row",
                margin: "auto",
                }}>
            <span style={{
                minWidth: 100,
                flex: 1,
                cursor: "pointer"
            }}>
                {schoolPicker()}
            </span>
            <span style={{
                minWidth: 225,
                flex: 4
            }}>
                {searchInput()}
            </span>
        </div>
    </div>;
};

// custom mag glass icon for search bar
const SearchDropdownIndicator: FunctionComponent = (props: any) => {
    return <div>
     <components.DropdownIndicator {...props}>
         <FontAwesomeIcon icon={faSearch} />
       </components.DropdownIndicator>
     </div>;
}

export default SearchBar;