import React, { Component } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
  LineChart
} from "recharts";
import Select from "react-select";
import { Helmet } from "react-helmet";
import { ICourse, ISection, IUniversity, ICourseSemesterData } from "./models/models";

interface ICustomizedAxisTickProps {
  x: number,
  y: number,
  payload: { value: string}
}

class CustomizedAxisTick extends Component<ICustomizedAxisTickProps> {
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-45)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

const strcmp = (a: string, b: string): -1 | 0 | 1 => a < b ? -1 : a > b ? 1 : 0;

export const gradeComparer = (a: IGrade, b: IGrade): -1 | 0 | 1  => {
  var g1 = a.grade;
  var g2 = b.grade;
  if (g1.length === 1) g1 = g1 + ","; // character between + and -
  if (g2.length === 1) g2 = g2 + ","; // character between + and -
  return strcmp(g1, g2);
}

export const sectionComparer = (a: ISection, b: ISection): -1 | 0 | 1 => {
  var aSeason: any, aYear, bSeason: any, bYear;
  if (a.semester[0] === "S") {
    if (a.semester[1] === "p") aSeason = "Spring";
    else if (a.semester[1] === "u") aSeason = "Summer";
    aYear = a.semester.substring(6);
  } else {
    aSeason = "Fall";
    aYear = a.semester.substring(4);
  }

  if (b.semester[0] === "S") {
    if (b.semester[1] === "p") bSeason = "Spring";
    else if (b.semester[1] === "u") bSeason = "Summer";
    bYear = b.semester.substring(6);
  } else {
    bSeason = "Fall";
    bYear = b.semester.substring(4);
  }
  if (aYear < bYear) return -1;
  if (aYear > bYear) return 1;

  if (aSeason === "Spring" && bSeason === "Summer") return -1;
  if (aSeason === "Summer" && bSeason === "Spring") return 1;
  if (aSeason < bSeason) return 1;
  if (aSeason > bSeason) return -1;
  return 0;
}

export const getGrades = (selected: ISection | ICourse): IGrade[] => {

  const grades = Object.entries(selected)
    .filter(entry => ["A+", "A", "A-", "B+", "B", "B-", "C+", "C", "C-", "D+", "D", "D-", "DR", "F", "W"].includes(entry[0]))
    .map(entry => ({ grade: entry[0], count: entry[1] }) );

  grades.sort(gradeComparer);

  return grades;
}

export const getSelectOptionsFromSections = (sections: ISection[]): SectionOption[] => {
  sections.sort(sectionComparer);

  for (var i = 0; i < sections.length; i++) {
    if (sections[i].semester[0] === "S") {
      sections[i].semester =
        sections[i].semester.substring(6) +
        " " +
        sections[i].semester.substring(0, 6) +
        " Section " +
        sections[i].section;
    } else if (sections[i].semester[0] === "F") {
      sections[i].semester =
        sections[i].semester.substring(4) +
        " " +
        sections[i].semester.substring(0, 4) +
        " Section " +
        sections[i].section;
    }
  }
  
  const options: SectionOption[] = sections
    .map((section, index) => ({ value: index, label: section.semester + " - " + section.instructor + " (" + section.gpa.toFixed(2) + ")"}));

  return options;
}
interface IClassPageProps {
  api: string,
  university: IUniversity,
  courseCode: string,
  key: string
}

interface IClassPageState {
  data: { course: ICourse, sections: {[sectionName: string]: ISection }} | null,
  selected: SectionOption | null,
  viewing: ISection | null,
  selectedGrades: IGrade[],
  options: SectionOption[],
  courseSemesterData: ICourseSemesterData[] | null,
}

interface IGrade {
  grade: string,
  count: number
}

// these are used in react-select
export type SectionOption = {
  label: string, 
  value: number
}

class ClassPage extends Component<IClassPageProps, IClassPageState> {
  constructor(props: IClassPageProps) {
    super(props);
    this.loadData = this.loadData.bind(this);

    this.state = {
      data: null,
      selected: null,
      viewing: null,
      selectedGrades: [],
      options: [],
      courseSemesterData: null,
    };
  }

  loadData() {
    fetch("/api/" + this.props.university.shortName + "/course/" + this.props.courseCode) 
      .then(response => response.json())
      .then(json => {
        const options = getSelectOptionsFromSections(json.sections);
        
        this.setState({
          data: json,
          viewing: json.sections[0],
          selectedGrades: getGrades(json.sections[0]),
          selected: options[0],
          options: options,
        });


      })
      .catch(ex => console.log("parsing failed", ex));
    
    fetch("/api/" + this.props.university.shortName + "/course/" + this.props.courseCode + "/gpabysemester")
      .then(response => response.json())
      .then(json => {
        this.setState({
          courseSemesterData: json
        });
      })
      .catch(ex => console.log("parsing failed", ex));
  }

  componentDidMount() {
    this.loadData();
  }

  onNewSectionClick = (newSection: SectionOption) => {
    if (this.state.data !== null && newSection !== null) {
      var selected = this.state.options[newSection.value];
      var selectedGrades = getGrades(this.state.data.sections[selected.value]);
      this.setState({
        viewing: this.state.data.sections[newSection.value],
        selected: newSection,
        selectedGrades: selectedGrades
      });
    }
  }

  render() {
    var data = this.state.data;
    var selectedGrades = this.state.selectedGrades;
    const grades: IGrade[] | null = this.state.data != null ? getGrades(this.state.data.course) : null;

    return (
      <section className="section" style={{minHeight: this.state.data === null ? 800 : 0}}>
        <Helmet>
          <title>{"VA Grades - " + this.props.university.longName + " - " + this.props.courseCode}</title>
          <meta name="description" content={`VA Grades has real grade data from ${this.props.university.longName}`}  />
        </Helmet>
        {this.state.data !== null && <div className="container">
          <div className="card">
            <div className="card-content">
              <p className="title has-text-weight-medium">{data?.course.class + " " + data?.course.title}</p>
              <p className="subtitle" />
              <ul>
                <li>Average GPA: {data?.course.avg.toFixed(2) ?? ""}</li>
                <li>Total Students: {data?.course.students ?? ""}</li>
              </ul>
            </div>
          </div>
          <br />
          <div className="columns is-desktop is-widescreen">
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <p className="title has-text-weight-medium">Grade Distribution All-Time</p>
                  <p className="subtitle" />
                  <ResponsiveContainer
                    width="100%"
                    height="100%"
                    minWidth={100}
                    minHeight={400}
                  >
                    <BarChart
                      data={grades ?? undefined}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <XAxis dataKey="grade" />
                      <YAxis />
                      <Tooltip />
                      <Bar type="monotone" dataKey="count" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <p className="title has-text-weight-medium">GPA Trend All Time</p>
                  <p className="subtitle" />
                  <ResponsiveContainer
                    width="100%"
                    height="100%"
                    minWidth={100}
                    minHeight={400}
                  >
                    <LineChart
                      data={Object.values(this.state.courseSemesterData ?? {}).map(entry => ({ 
                        semester_name: entry.semester_name, 
                        gpa: entry.gpa?.toFixed(2) 
                      }))}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <XAxis
                        dataKey="semester_name"
                        height={135}
                        tick={<CustomizedAxisTick x={0} y={0} payload={{value: "?"}} />}
                      />
                      <YAxis />
                      <Tooltip />
                      <Line type="monotone" dataKey="gpa" fill="#82ca9d" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
                <footer className="card-footer" />
              </div>
            </div>
          </div>
          <br />
          <div className="card">
            <div className="card-content">
              <div className="columns">
                <div className="column">
                  <p className="title has-text-weight-medium">Section Grade Distribution</p>
                  <p className="subtitle">GPA: {this.state.viewing?.gpa.toFixed(2) ?? ""}</p>
                </div>
                <div className="column">
                  <Select
                    value={this.state.selected}
                    options={this.state.options}
                    onChange={(e: any ) => this.onNewSectionClick(e)}
                  />
                </div>
              </div>
              <ResponsiveContainer
                width="100%"
                height="100%"
                minWidth={100}
                minHeight={400}
              >
                <BarChart
                  data={selectedGrades}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <XAxis dataKey="grade" />
                  <YAxis />
                  <Tooltip />
                  <Bar type="monotone" dataKey="count" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>}
      </section>
    );
  }
}

export default ClassPage;
