import { FunctionComponent } from "react";

const Contact: FunctionComponent = () => {
  return (
    <div className="container content">
      <div className="section">
        <div className="box">
          <h3>Contact</h3>
          <p>
            Find a bug in the site? Want to request a school to be added?
            
            Send an email to dav3nu@virginia.edu with your feedback!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
