import { FunctionComponent } from "react";
import { universities } from "./models/models";
import SearchBar from "./SearchBar";

const Home: FunctionComponent = () => {
  return (
    <div className="container content">
      <div className="section">
        <div className="notification is-info">
          Spring 2021 data is now available for JMU and UVA!
        </div>
      </div>
      <div className="hero">
        <div className="hero-body">
          <div className="title has-text-weight-normal has-text-centered">
            Search <b>real grade data</b> from your school
          </div>
        </div>
      </div>
          <div style={{width: "50%", minWidth: 340, margin: "auto"}}>
            <SearchBar selectedUniversity={universities["uva"]}/>
          </div>
    </div>
  );
}

export default Home;
