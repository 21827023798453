import { FunctionComponent, useState, useEffect } from "react";
import "whatwg-fetch";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import rehypeRaw from 'rehype-raw'
import { Link } from "react-router-dom";
import { IBlogPost } from "./models/models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

interface IBlogPostProps {
  slug: string
}

const BlogPost: FunctionComponent<IBlogPostProps> = (props: IBlogPostProps) => {
  const [post, setPost] = useState<IBlogPost | null>(null);

  useEffect(() => {
    fetch("/api/blog/" + props.slug)
      .then(response => response.json())
      .then(json => setPost(json));
  }, []);

  return (
      <div className="container content">
        {post !== null && <Helmet>
            <title>{"VA Grades - Blog - " + post.metadata.title}</title>
          </Helmet>}
        <div className="columns is-centered">
          <div className="column is-centered is-two-thirds">
            {post !== null && <div className="">
              {post.metadata.image !== null && <div className="section is-centered" style={{paddingTop: ".5rem", paddingBottom: ".5rem"}}>
                  <figure className="image is-centered" style={{width: "100%", height: "auto", margin: "auto"}}>
                   <img src={post.metadata.image} alt={post.metadata.alt ?? ""} />
                   <figcaption style={{justifyContent: "center", fontSize: "small"}}>{post.metadata.attribution}</figcaption>
                  </figure>
                </div>}
              <div className="section" style={{paddingTop: ".5rem"}}>
                <div className="title has-text-weight-medium">{post?.metadata.title}</div>
                <div className="subtitle is-italic is-size-6">{post?.metadata.description}</div>
                <div className="subtitle is-size-6">
                  <span style={{paddingRight: "1rem"}}>
                    <FontAwesomeIcon icon={faCalendar} />
                  </span>
                  {new Date(post?.metadata.date).toDateString()}
                </div>
                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={post?.content ?? ""} />
              </div></div>}
            </div>
          </div>
        { post !== null && 
          <div className="section">
            <Link to="/blog" className="button">
              back to blog
          </Link>
          </div>}
      </div>    
  );
}

export default BlogPost;
