import { FunctionComponent } from "react";

const Privacy: FunctionComponent = () => {
  return (
    <div className="container content">
      <div className="section">
        <div className="box">
          <h3>Privacy Policy</h3>
          <p>
            All grade data is is anonymous and aggregated. 
            We use Google Analytics to improve the site.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
