import { FunctionComponent } from "react";
import { Link, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./Home";
import College from "./College";
import ClassPage from "./ClassPage";
import About from "./About";
import Contact from "./Contact";
import Privacy from "./Privacy";
import Blog from "./Blog";
import BlogPost from "./BlogPost";
import Status from "./Status";
import SearchBar from "./SearchBar";
import logo from "./svg/logo.svg";
import { universities } from "./models/models";

const App: FunctionComponent = () => {
  const location = useLocation();
  const selectedUniversity = universities[location.pathname.split("/")[1]] ?? universities["uva"];

  return (
    <div>
      <Helmet>
        <title>VA Grades</title>
        <meta name="description" content="VA Grades makes real grade data from universities searchable" />
      </Helmet>
      <nav className="navbar" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item is-tab" href="/">
            <img src={logo} width="60" height="28" />
          </a>
          <div className="navbar-burger" data-target="mainMenu" aria-label="menu" aria-expanded="false">
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className="navbar-menu" id="mainMenu">
          <div className="navbar-start">
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">Schools</a>
              <div className="navbar-dropdown ">
                {Object.values(universities).map(university => 
                    <Link className="navbar-item" key={university.shortName}
                      to={"/" + university.shortName}>{university.longName}
                    </Link>
                  )}
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <Link className="navbar-item" to="/blog">
              Blog
            </Link>
            <Link className="navbar-item" to="/about">About</Link>
            <div style={{margin: "auto", paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15}}>
              <SearchBar key={location.pathname} selectedUniversity={selectedUniversity} />
            </div>
          </div>
        </div>
      </nav>
      <div className="routes" style={{minHeight: "78vh"}}>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/status" component={Status} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/blog/:slug" render={(props) => <BlogPost slug={props.match.params.slug}/>} />
        
        {Object.values(universities)
          .map(university => 
          <Route key={university.shortName}
            exact path={"/" + university.shortName} 
            render={() => <College university={university} api={"/api/" + university.shortName + "/course"} link={"/" + university.shortName + "/"} />} 
          />)}
        {Object.values(universities)
          .map(university => 
          <Route key={university.shortName}
            exact path={"/" + university.shortName + "/:course"}
            render={props => 
              <ClassPage 
                api={"/api/" + university.shortName + "/course/"} 
                university={university} 
                key={university.shortName + "/" + props.match.params.course}
                courseCode={props.match.params.course ?? ""}/>} 
        />)}
      </div>
      <div className="footer">
        <div className="section has-text-centered m-0 p-0">
          <img src={logo} width="40" height="28" />
          <div>Our data comes directly from universities</div>
        </div>
        <div className="columns">
          <div className="column is-three-quarters" />
            <div className="column is-one-quarter">
              <div className="has-text-bottom is-justify-content-right">
                <span style={{padding: "1rem"}}><Link to="/contact">Contact</Link></span>
                <span style={{padding: "1rem"}}><Link to="/privacy">Privacy</Link></span>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

document.addEventListener("DOMContentLoaded", function() {
  // Get all "navbar-burger" elements
  var $navbarBurgers = Array.prototype.slice.call(
    document.querySelectorAll(".navbar-burger"),
    0
  );

  // Check if there are any nav burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach(function($el) {
      $el.addEventListener("click", function() {
        // Get the target from the "data-target" attribute
        var target = $el.dataset.target;
        var $target = document.getElementById(target);

        // Toggle the class on both the "navbar-burger" and the "navbar-menu"
        $el.classList.toggle("is-active");
        if ($target != null) {
          $target.classList.toggle("is-active");
        }
      });
    });
  }
});

export default App;
