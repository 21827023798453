import ReactDOM from "react-dom";
import React from "react";
import { Router } from "react-router-dom";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import App from "./App";
import { unregister } from "./ServiceWorker";
import "./index.css";

const history = createBrowserHistory();

ReactGA.initialize("UA-83384720-1"); 

history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const index: React.ReactElement = 
    <Router history={history}>
        <App />
    </Router>

ReactDOM.render(index, document.getElementById("root"));

// remove service worker
unregister();
