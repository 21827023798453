import { FunctionComponent } from "react";

const About: FunctionComponent = () => {
  return (
    <div className="container content">
      <div className="section">
        <div className="box">
          <h3>About</h3>
          <p>
            This site is a project created and maintained by two
            graduates of the University of Virginia.
          </p>
          <p>
            Both students found
            it difficult to find quality information about grades and
            distributions. The data contained in the website was the result of
            various requests to the University after the Spring 2016 semester.
          </p>
          <p>
            The creators hope that the tool
            allows students to make more informed judgements about choosing
            classes, and provides transparency about grading practices.
          </p>
          <p>
            For the technically minded,
            the site is based on a NodeJS server with a ReactJS front end and
            the data is held in a Postgres database.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
